import { default as React, useEffect } from "react"
import Layout from "~/components/layout"
import Seo from "~/components/seo"

const CalculatorPage = ({ location: { search }}) => {
  useEffect(() => {
    window.TEMPLATE_ID = "www.kirkwoodbank.com_1";
    window.CALCULATORID = "";
    window.PASSTHROUGH = "";
    window.tvcCalculatorHtml = "";

    var tvcScriptElement = document.createElement('script');
    var tvcHttp;
    if (document.location.href.substring(0, 5) === "https") { tvcHttp = "https://"; } else { tvcHttp = "http://"; }
    tvcScriptElement.src = tvcHttp + "www.TimeValueCalculators.com/timevaluecalculators/Calculator2.aspx?version=" + Math.random() + "&" + window.createQueryString();
    tvcScriptElement.onload = window.tvcOnceLoaded;
    document.getElementById('tvcMainCalculatorDivId').appendChild(tvcScriptElement);
  }, [])

  return (
    <Layout>
      <Seo title="Calculator" />
      <section className="pt-40 py-16 bg-primary">
        <div className="container mx-auto px-8">
          <div className="flex -mx-8">
            <div className="w-full px-8">
              <h1 className="text-white">Calculators</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full px-8">
              <div
                onClick={e => {
                  e.stopPropagation()
                }}
                role="presentation"
                id="tvcMainCalculatorDivId" className="cTimeValue">
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CalculatorPage